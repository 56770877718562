/* Settings */
:root{
    --white: #fff;
    --gray: #6c757d;
    --secondary: #6c757d;
    --success: #198754;
    --info: #0dcaf0;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;

    --body-bg: #f8f9fe;
    
    --border: 1px solid rgba(136,152,170,.05);
    --border-round: 0.75rem;

    --box-shadow: 0 0.5rem 0.5rem rgba(136,152,170,.15);

    --linear-background: linear-gradient(87deg,#172b4d,#1a174d);
    --linear-background-header: linear-gradient(87deg,#11cdef,#1171ef);
}

/* Base */
body {
    position: relative;
    transition: 0.5s;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    background-color: #f0f5fc;
}

a{
    text-decoration: none !important;
}

section{
    padding: 2rem;
}

.h1,
h1 {
 font-size:1.625rem
}
.h2,
h2 {
 font-size:1.5rem
}
.h3,
h3 {
 font-size:1.0625rem
}
.h4,
h4 {
 font-size:.9375rem
}
.h5,
h5 {
 font-size:.8125rem
}
.h6,
h6 {
 font-size:.625rem
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}

footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
}

footer a,
footer p{
    color: white;
    transition: all 0.5s;
}

footer:hover a,
footer:hover p{
    color: var(--bs-cyan) !important
}

/* Objects */
.main{
    min-height: 100vh;
    background-color: var(--body-bg);
}

.container{
    z-index: 10;
}

nav.navbar{
    transition: all 0.4s;
}

nav.navbar.active{
    background-color: white;
}

.main-header{
    position: relative;
    background: var(--linear-background-header);
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
}

.main-header>div{
    padding-left: 2rem;
    padding-right: 2rem;
}

/* Components */
.modal-content{
    border-radius: var(--border-radius);
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-control-label,
.form-label {
    color: #525f7f;
    font-size: .875rem;
    font-weight: 600;
}

.toast{
    position: fixed;
    z-index: 999;
    right: 2rem;
    top: 5rem;
    background-color: white;
}

.card-footer-full-width{
    position: absolute;
    left: 0;
    width: 100%;
    height: 2.2rem;
    bottom: 0;
    border-bottom-left-radius: var(--border-round);
    border-bottom-right-radius: var(--border-round);
    color: white;
    padding: 0.5rem 1.5rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    display: flex;
}

tbody tr{
    vertical-align: middle;
}

tbody td{
    text-transform: capitalize;
}

.nav-item{
    margin: 0
}

.nav-item a{
    padding: 6px 16px 6px;
    display: block;
    text-decoration: none;
    color: white;
    transition: all 0.4s;
    font-size: 1rem;
    font-weight: bold;
}

nav.navbar.active a{
    color: black !important;
}

.dashboard .nav-item a{
    padding: 6px 16px 6px;
    display: block;
    text-decoration: none;
    color: black;
    transition: all 0.4s;
    font-size: .9rem;
}

.dashboard .nav-item a svg{
    margin-right: 0.5rem;
}

.custom-radios div,
.custom-radios-icon div {
    display: inline-block;
}
.custom-radios input[type=radio],
.custom-radios-icon input[type=radio] {
    display: none;
}
.custom-radios input[type=radio] + label,
.custom-radios-icon input[type=radio] + label {
    color: #333;
    font-size: 14px;
}
.custom-radios input[type=radio] + label span,
.custom-radios-icon input[type=radio] + label span {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin: -1px 4px 0 0;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    line-height: 38px;
}
.custom-radios input[type=radio] + label span svg {
    opacity: 0;
    transition: all 0.3s ease;
}
.custom-radios input[type=radio]:checked + label span svg {
    opacity: 1;
}

.custom-radios-icon input[type=radio] + label span {
    opacity: 0.7;
    transition: all 0.3s ease;
}
.custom-radios-icon input[type=radio]:checked + label span {
    opacity: 1;
}

.brand-logo{
    width: 150px;
}

.hero {
    background: var(--linear-background-header);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    overflow: hidden;
    padding-top: 180px;
    padding-bottom: 250px;
    height: 100vh;
}

.hero-divider{
    position: absolute;
    left: 0;
    bottom: 0;
}

.hero-divider-top{
    position: absolute;
    left: 0;
    top: 0;
}

.hero-image{
    position: relative;
    z-index: 99;
}

.hero-image img{
    width: 40vw;
}



/* Utilities */
.mt-n-7{
    margin-top: -6rem !important;
}

.height-13{
    height: 13rem;
}

.fadeIn {
    animation: 0.5s fadeIn forwards;
}

.fadeOut {
    animation: 0.5s fadeOut forwards;
}

.hero h6{
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 27px;
}

.hero h1{
    font-size: 54px;
    line-height: 64px;
    font-weight: 700;  
    margin-bottom: 21px;
}

.hero p{
    font-size: 20px;
    line-height: 30px;
}

.hero-badge{
    max-width: 200px;
    margin-right: 2rem;
    margin-top: 2rem;
}

.title{
    font-size: 3rem;
    margin-bottom: 1.5rem;
}

.subtitle{
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.description{
    font-size: 1.25rem;
}

/* Color */
.bg-gradient-default {
    background: var(--linear-background);
}

.bg-linear-gradient-default {
    background: var(--linear-background-header);
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
    
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}